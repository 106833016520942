button {
    border: none;
    font-family: Belleza, serif;
    font-size: 35px;
    font-weight: lighter;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    background-color: #653117;
    color: white;
    z-index: 5;
    cursor: pointer;
}

.white-button{
    color:  #653117;
    background-color: white;
}