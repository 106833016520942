.outer-chapter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inner-chapter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    text-align: center;
    width: 1400px;
}

@media (min-width: 100px) and (max-width: 1400px) {
    .inner-chapter {
        width: 300px;
    }
}

