@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Belleza&family=Open+Sans:wght@300&display=swap');

*{
    margin: 0;
    box-sizing: border-box;
}

.stars {
    width: 150px;
}

.guy {
    width: 600px;
    margin-right: 100px;
    opacity: 0.6;
    margin-bottom: 100px;
}

.section-divider {
    border-bottom: 1px solid #ddd;
    margin-top: 50px;
}

/*.loader-container {*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    position: fixed;*/
/*    background: rgba(0, 0, 0, 0.834);*/
/*    z-index: 1;*/
/*}*/



.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #653117 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
    z-index: 5;
}

.desk {
    width: 500px;
    margin-left: 100px;
    opacity: 0.6;
}

h1 {
    font-family: Logo, serif;
    font-size: 80px;
    font-weight: lighter;
    color: #653117;
}

h2 {
    font-family: Belleza, serif;
    font-size: 45px;
    font-weight: lighter;
    color:  #653117;
}

h3{
    font-family: Belleza, serif;
    font-size: 40px;
    font-weight: lighter;
    color:  #653117;
}

h4 {
    font-family: Belleza, serif;
    font-size: 30px;
    font-weight: lighter;
    color:  #653117;
    z-index: 1;
}

h5{
    font-family: Belleza, serif;
    font-size: 20px;
    font-weight: lighter;
    color:  #653117;
}

p, ul, li{
    font-family: Belleza, serif;
    font-size: 25px;
    font-weight: lighter;
    color:  #653117;
}

.inner-div li{
    text-align: left;
}

.outer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.inner-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 1400px;
}

.inner-bottom h2{
    padding: 0;
    margin: 0;
}

.inner-bottom input{
    margin-top: 20px;
}

.outer-div-praktijkvoorbeeld {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.inner-div-praktijkvoorbeeld {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
    text-align: center;
    width: 1400px;
    margin-bottom: 100px;
}

.voorbeeld {
    width: 600px;
    text-align: left;
}

.inner-bottom-text, .inner-bottom-form{
    width: 500px;
}

.title {
    text-align: center;
}

@font-face {
    font-family: 'Logo';
    src: url("../src/Fonts/Nickainley.otf");
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media (min-width: 100px) and (max-width: 1400px) {
    .stars {
        width: 150px;
    }

    .guy{
        width: 300px;
        margin: 0;
    }
    .desk {
        width: 300px;
        margin-left: 0;
        margin-top: 100px;
    }
    h1 {
        font-family: Logo, serif;
        font-size: 40px;
        font-weight: lighter;
        color: #653117;
    }

    .white-button {
        margin-top: 50px;
    }

    .outer-div-praktijkvoorbeeld {
        flex-direction: row;
    }

    .inner-div-praktijkvoorbeeld {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 300px;
    }

    .voorbeeld {
        width: 300px;
    }

 .outer-bottom{
     flex-direction: row;
 }

 .inner-bottom {
     flex-direction: column-reverse;
     justify-content: flex-start;
     align-items: center;
 }

 .inner-bottom-text {
     width: 300px;
 }


    .inner-bottom-form {
        width: 300px;
    }


    .inner-bottom h2 {
        margin-top: 50px;
        margin-bottom: 40px;
    }

    .inner-bottom button {
        margin-top: 50px;
    }

    h2 {
        font-family: Belleza, serif;
        font-size: 45px;
        font-weight: lighter;
        color:  #653117;
    }

    h3{
        font-family: Belleza, serif;
        font-size: 40px;
        font-weight: lighter;
        color:  #653117;
    }

    h4 {
        font-family: Belleza, serif;
        font-size: 30px;
        font-weight: lighter;
        color:  #653117;
    }

    h5{
        font-family: Belleza, serif;
        font-size: 20px;
        font-weight: lighter;
        color:  #653117;
    }


}

