.outer-three {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
 .inner-three{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: flex-start;
     margin-top: 50px;
     text-align: center;
     width: 1400px;
 }

 .inner-three div{
     width: 350px;
     margin-bottom: 100px;
     /*padding: 50px;*/
 }
 
 @media (min-width: 100px) and (max-width: 1400px){
     .outer-three{
         display: flex;
         flex-direction: row;
         width: 100%;
     }

     .inner-three{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;
         width: 300px;
     }
 }
