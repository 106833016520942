.outer-how-it-works {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.inner-how-it-works{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
    text-align: center;
    width: 1400px;
}

.inner-how-it-works div{
    width: 300px;
    margin-bottom: 100px;
    margin-left: 50px;
}

@media (min-width: 100px) and (max-width: 1400px) {
    .outer-how-it-works {
        flex-direction: row
    }
    .inner-how-it-works {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 300px;
    }

    .inner-how-it-works div {
        margin-left: 0;
    }
}
