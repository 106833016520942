.outer-two-page, .outer-div, .why-trust-us-outer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.inner-two-page-vertrouwen, .inner-two-page-reversed, .why-trust-us-inner{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    margin-top: 50px;
    text-align: center;
    width: 1400px;
}


.why-trust-us-inner{
    flex-direction: column;
    text-align: left;
}

.inner-two-page-reversed {
    flex-direction: row;
}

.inner-two-page-vertrouwen div {
    width: 700px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: initial;
}

.two-page-text-1{
    width: 672px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: initial;
}

.two-page-text-1 img{
    width: 500px;
    opacity: 60%;
}


.inner-two-page-reversed img{
    width: 700px;
}
.inner-two-page-vertrouwen img{
    width: 600px;
    margin-right: 40px;

}

.inner-div{
    width: 500px;
}

form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

form h4, form h2{
    text-align: center;
    margin: 20px;
}

input {
    font-family: Belleza, serif;
    font-size: 30px;
    border-color:  #653117;
    border-radius: 10px;
    border-width: medium;
    height: 75px;
    width: 350px;
    text-align: center;
    margin: 10px;
}

.why-wage-buddy-inner, .why-trust-us-inner{
    width: 500px;
}

.outer-div-praktijkvoorbeeld{
    margin-top: 40px;
}

@media (min-width: 100px) and (max-width: 1400px) {
    .outer-two-page{
    flex-direction: row;
    }
    .inner-two-page, .inner-two-page-reversed{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100px;
    }

    .inner-two-page-vertrouwen {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
    }

    .why-trust-us-outer{
        display: flex;
        flex-direction: row;
    }

    .why-trust-us-inner {
        display: flex;
        flex-direction: column;
        width: 50px;
    }

    .two-page-text-2{
    width: 10px;
    }

    .two-page-text-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .inner-two-page-vertrouwen img, .inner-two-page-reversed img {
        width:50%;
        align-self: center;
        justify-self: center;
    }

    .two-page-text-1{
        text-align: center;
        padding: 50px;
    }

    .why-wage-buddy-inner, .why-trust-us-inner {
        margin-top: 0;
        width: 300px;
    }

    .why-trust-us-inner h2{
        text-align: center;
    }

    .outer-div-praktijkvoorbeeld {
        /*flex-direction: row;*/
    }

    .inner-div-jonne {
        /*flex-direction: column;*/
        /*justify-content: flex-start;*/
        /*align-items: center;*/
        width: 100px;
    }
}