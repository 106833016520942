.outer-border{
    position: relative;
}

.outer-border{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-image: url('../../assets/banner-klein.png');
    background-size: cover;
    height: 750px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center top;
    background-clip: content-box;
}

.inner-border {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
    width: 1400px;
    margin-left: 100px;
    margin-right: 100px;
}

.text-1, .text-2, .text-3 {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    justify-content: space-around;
    align-items:center;
    width: 400px;
    text-align: center;
    background-blend-mode: darken;
}

@media (min-width: 100px) and (max-width: 1400px) {

    .outer-border {
        width: 100%;
    }
    .inner-border {
        width: 80%;
    }

    .text-1, .text-2, .text-3 {
        width: 300px;
        padding: 0;
        margin: 0;
    }
}

